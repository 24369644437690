import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box
} from "@mui/material";
import { useContext } from "react";
import { PSContext } from "../Contexts";
import CloseIcon from "@mui/icons-material/Close";
import LabelValueList from "./LabelValueList";
import { useTranslation } from "react-i18next";
import { getByDisplayKey } from "../utils/displayKeyUtil";
import { grey } from '@mui/material/colors'

/**
 * Modal containing details about an entry in a PS section.
 */
const EntryDetailsModal = ({ open, onClose, entry, entryConfig }) => {
  const ps = useContext(PSContext);
  const [translate] = useTranslation();

  const children = []

  //If the entry has potential children, fetch the references
  entryConfig.nestedFields?.forEach((nested) => {
    const childrenReferences = getByDisplayKey(entry, nested.idKey);

    // Get the entry object and pertinent fields for each reference
    if (childrenReferences) {
      childrenReferences.forEach((child) => {
        children.push({
          'entry': ps.entry.find((section) => section.fullUrl === child.reference),
          'fields': nested.fields
        })
      })
    }
  })

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyItems: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography variant="body_lg" sx={{ flexGrow: 1, textAlign: "center" }}>
          {translate(entryConfig.modalLabel)}
        </Typography>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 3, px: 3, display: 'flex', flexDirection: 'column' }}>
        <LabelValueList divider entry={entry} fields={entryConfig.fields} />
        {children && children.map((child) => (
          <Box backgroundColor={grey[200]} sx={{ px: 3, mb: 3, borderRadius: 1 }}>
            <LabelValueList divider entry={child.entry} fields={child.fields} />
          </Box>
        ))}
        {entryConfig.showDisclaimer && entryConfig.showDisclaimer(entry) && (
          <Typography variant="body-sm" sx={{ alignSelf: 'flex-end', color: 'text.secondary' }}>{translate(entryConfig.disclaimerLabel)}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default EntryDetailsModal;
