import { List, ListItem, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getByDisplayKey } from "../utils/displayKeyUtil";
import { htmlStringToText } from "../utils/dataUtil";

const LabelValueList = ({ entry, fields }) => {
  const [translate, i18n] = useTranslation();

  const filteredFields = fields.map(field => {
    const value = getByDisplayKey(entry, field.value, '', i18n.language);
    return { field, value };
  }).filter(item => item.value);

  return (
    <List>
      {filteredFields.map(({field, value}, index) => {
        const content = (
          <Typography
            variant="body_md"
            sx={{
              color: 'primary.main',
              whiteSpace: field.note ? 'preserve nowrap' : 'normal',
              ...(field.textTransform && field.textTransform(value)),
            }}
          >
            {field.vanityLabel
              ? translate(field.vanityLabel + '.' + value)
              : htmlStringToText(value)}
          </Typography>
        );
  
        return (
          <ListItem
            key={field.label}
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              px: 0,
              py: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start'
            }}
            divider={index < filteredFields.length - 1}
          >
            <Typography variant="body_sm" sx={{ color: 'text.secondary', flexShrink: 0 }}>
              {translate(field.label)}
            </Typography>
            {field.note ? (
              <Box sx={{ overflowX: 'auto', maxWidth: '100%'}}>
                {content}
              </Box>
            ) : (
              content
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default LabelValueList;
