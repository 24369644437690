/**
 * @fileoverview We use configurable display keys to map data object properties to values in UI. This file contains functions for using display keys.
 */

import { formatDateAsText } from "./dateUtil";

/**
 * Transformations that can be applied to display data. Only for simple, superficial transformations (ex. converting text to uppercase).
 * Never mutate or modify the data itself.
 */
const TransformFunctions = {
  /**
   * Given a data object and a path to a date value, get the date value and format it.
   * @param {object} obj Data object.
   * @param {string} key Key to date value within data object.
   * @param {string} lang Language code to format date to.
   * @returns {string} Formatted date.
   */
  DATE_AS_TEXT: (obj, key, lang) => formatDateAsText(getNestedByDisplayKey(obj, key.split('.')), lang),
  /**
   * Given a data object and a path to a date value, get the date value and format it including time.
   * @param {object} obj Data object.
   * @param {string} key Key to date value within data object.
   * @param {string} lang Language code to format date to.
   * @returns {string} Formatted date.
   */
  DATE_TIME_AS_TEXT: (obj, key, lang) => formatDateAsText(getNestedByDisplayKey(obj, key.split('.')), lang, true),
  /**
   * Given a path to an array in a data object, use the array items to create a single string.
   * @param {object} obj Data object.
   * @param {string} listKey Key to array value within data object. Assume this is an array of objects.
   * @param {string} valueKey Key to value within each array item that will be used to build the string.
   * @returns {string} A string value.
   */
  JOINED_LIST: (obj, listKey, valueKey) => {
    // Get paths from complex keys
    const [listPath, valuePath] = [listKey.split('.'), valueKey.split('.')];

    const list = getNestedByDisplayKey(obj, listPath, []);
    const values = list.map((i) => getNestedByDisplayKey(i, valuePath, '')).filter((i) => i);
    return values.length >= 1 ? values.join(', ') : '';
  },
  /**
   * Given a path to an array in a data object, find a specific item in the array and return a value from the selected item.
   * @param {object} obj Data object.
   * @param {string} listKey Key to array value within the data object. Assume this is an array of objects.
   * @param {string} matchKey Key to value within array items that will be used to find a specific item.
   * @param {string} match Select the first array item whose value at `matchKey` equals this value.
   * @param {string} valueKey Key to value within the selected array item.
   * @returns {*} Value within the selected array item.
   */
  MATCHED_VALUE: (obj, listKey, matchKey, match, valueKey) => {
    // Get paths from complex keys
    const [listPath, matchPath, valuePath] = [listKey.split('.'), matchKey.split('.'), valueKey.split('.')];

    // Find matching object in list and return its value
    const list = getNestedByDisplayKey(obj, listPath, []);
    const matchedValue = list.find((i) => getNestedByDisplayKey(i, matchPath) === match);
    return getNestedByDisplayKey(matchedValue, valuePath);
  },
  /**
   * Given a data object and a path to a value, split on colons and use the first element. Used in cases of exam results.
   * @param {object} obj Data object.
   * @param {string} key Key to value within data object.
   * @returns {string} First piece of value, delineated by a colon
   */
  FIRST_ELEMENT: (obj, key) => (getByDisplayKey(obj, key).split(':')[0]),
  /**
   * Given a data object and paths to values, fetch all values and append into a single string.
   * @param {object} obj Data object.
   * @param {array} key1 Key to value within data object.
   * @param {array} key2 Key to value within data object.
   * @returns {string} Concatenated string
   */
  COMPOUND_VALUE: (obj, key1, key2) => {
    const values = [getByDisplayKey(obj, key1), getByDisplayKey(obj, key2)];
    return values.filter(value => value).join(' ');
  },
};

/**
 * Given a data object and a path to some property in the object, get the value at the path.
 * @param {object} obj Data object.
 * @param {Array} path Path to property within object. Must come from display key object.
 * @param {*} fallback Value to return if path is invalid or item does not exist.
 * @returns {*} Value for path or fallback value.
 */
const getNestedByDisplayKey = (
  (obj, path, fallback) => path.reduce((acc, val) => (acc && acc[val] ? acc[val] : fallback), obj)
);

/**
 * Given a data object and a display key, use the path in the key to get a value. Nested keys must
 * use dot-notation to specify path to value.
 * @param {object} obj Data object.
 * @param {string} displayKey Display key from the configuration.
 * @param {*} fallback Value to return if path is invalid or item does not exist. Optional. Default value is empty string.
 * @returns {*} Value for display key or fallback value.
 */
export const getByDisplayKey = (obj, displayKey, fallback = '', lang = 'en') => {
  // If data or display key does not exist, then return fallback value
  if (!obj || !displayKey) {
    return fallback;
  }

  // If transform option is provided, then apply transform function
  const [transform, ...params] = displayKey.split(':::');
  if (transform && params?.length) {
    return TransformFunctions[transform](obj, ...params, lang) || fallback;
  }

  // If nested display key, then fetch using path
  const path = displayKey.split('.');
  if (path.length > 1) {
    return getNestedByDisplayKey(obj, path, fallback);
  }

  // Otherwise, assume plain object key and return value
  return obj[displayKey] || fallback;
};
