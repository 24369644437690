import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import RoutingPaths from "../constants/routingPaths";
import LandingPage from "../pages/LandingPage";
import PSLayout from "../layouts/PSLayout";
import SectionPage from "../pages/SectionPage";
import ErrorPage from "../pages/ErrorPage";

const Routing = () => (
  <Routes>
    <Route path={`${RoutingPaths.VIEWER}/${RoutingPaths.SHL_PARAM}`} element={<ProtectedRoute />}>
      <Route element={<PSLayout />}>
        <Route index element={<LandingPage />} />
        <Route path={RoutingPaths.SECTION_PARAM} element={<SectionPage />} />
      </Route>
    </Route>
    <Route path="*" element={<ErrorPage />}/>
  </Routes>
);

export default Routing;
