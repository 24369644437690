import { ChevronRight } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const SectionRow = ({ section }) => {
  const [translate] = useTranslation();
  return (
    <Link to={section.id} style={{ textDecoration: 'none' }}>
      <Card key={section.title} sx={{
        width: '100%',
        borderRadius: 2,
        boxShadow: 0,
      }}>
        <CardActionArea>
          <CardContent>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body_lg">{translate(section.titleLabel)}</Typography>
              <ChevronRight fontSize="large" />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default SectionRow;
