import { ThemeProvider } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import configureI18n from "./i18n";
import { theme } from "./theme";
import ErrorPage from './pages/ErrorPage';
import Routing from "./routing/Routing";

configureI18n();

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <Routing />
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
