import { Backdrop, CircularProgress, Container } from "@mui/material";

const LoadingSpinner = ({ color = 'primary', backdrop, size, thickness}) => {
  return backdrop ? (
    <Backdrop sx={{ height: '100%', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open>
      <CircularProgress color="inherit" size={size} thickness={thickness} />
    </Backdrop>
  ) : (
    <Container style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress color={color} size={size} thickness={thickness} />
    </Container>
  );
};

export default LoadingSpinner;
