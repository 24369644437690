/**
 * @fileoverview Utilities for fetching data with the JS `fetch` API.
 */

import { ErrorTypes } from '../constants';
import { GenericError, VSError } from '../model/error';

/**
 * Executes a fetch request. Passes errors to the error boundary API.
 * @param {string} url Request URL.
 * @param {object} config Request config.
 * @param {Function} showBoundary Error boundary function.
 * @returns The response.
 */
export const safeFetch = async (url, config, showBoundary) => {
  try {
    const response = await fetch(url, config);
    if (response?.ok) {
      return response;
    } else {
      // Check error body to see if it is a VeroSource error
      const body = await response.json();

      // Check if the error was caused by invalid PIN
      if (response.status === 401) {
        // If response contains number of attempts left, then return them in the response object
        if (body?.remainingAttempts) {
          return { ...response, remainingAttempts: body.remainingAttempts };
        }

        // If max attempts reached, set remaining attempts to zero and return response objects
        if (body?.error?.exception === 'MAX_ATTEMPTS_REACHED') {
          return { ...response, remainingAttempts: 0 };
        }
      }

      if (body.message === 'Manifest is expired') {
        throw new VSError(ErrorTypes.EXPIRED, response.status, body.error.message, '', body.error.exception);
      }

      // Check if the error was caused by a patient summary not found exception
      if (response.status === 404) {
        throw new VSError(ErrorTypes.NOT_FOUND, response.status, body?.error?.message, '', body?.error?.exception);
      }

      // Check if the error was caused by the bundle still being generated.
      if (response.status === 422) {
        throw new VSError(ErrorTypes.GENERATING, response.status, body.error.message, '', body.error.exception);
      }

      // Otherwise, create new VS error and throw it
      if (body?.error && body.error.exception && body.error.message) {
        // FIXME: Set the error type correctly depending on the VS response
        throw new VSError(ErrorTypes.UNKNOWN, response.status, body.error.message, '', body.error.exception);
      }

      // If it is not a VeroSource error, then throw a generic error
      throw new GenericError(ErrorTypes.UNKNOWN, response.status, response.statusText);
    }
  } catch (error) {
    if (error.name === 'AbortError') {
      // Request was aborted, so ignore the error and continue
      return null;
    }
    showBoundary(error);
  }
};

//Formatter for html strings
export const htmlStringToText = (string) => (!string ? ['']
  : string.replaceAll('<br>', '\n')
    .replaceAll('<p>', '')
    .replaceAll('<div xmlns="http://www.w3.org/1999/xhtml">', '')
    .replaceAll('</div>', '')
    .replaceAll('&amp;', '&')
    .replaceAll('&quot;', '"')
    .replaceAll('&tilde;', '~')
    .replaceAll('&lt;', '<')
    .replaceAll('&gt;', '>')
    .replaceAll('&ndash;', '-')
    .replaceAll('&ndash;', '—'));