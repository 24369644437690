import { Stack } from "@mui/material";
import appConfig from "../config/appConfig";
import JSONCard from "../components/JSONCard";
import SectionRow from "../components/SectionRow";
import displayConfig from "../config/displayConfig";
import { useContext } from "react";
import { PSContext } from "../Contexts";

/**
 * Display section rows for the Patient Summary sections.
 */
const LandingPage = () => {
  const ps = useContext(PSContext);
  const supportedSections = displayConfig[appConfig.spec].sections;
  return (
    <Stack direction='column' gap={2} sx={{ width: "100%" }}>
      {supportedSections.map((section) => <SectionRow key={section.id} section={section} />)}
      {appConfig.displayJSONBlob && <JSONCard ps={ps} />}
    </Stack>
  );
};

export default LandingPage;
