import { Container } from "@mui/material";

const PageLayout = ({ children, header, footer }) => {
  return (
    <Container
        maxWidth={false}
        disableGutters
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
      {header}
      <Container
        maxWidth='sm'
        disableGutters
        sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
      >
        {children}
      </Container>
      {footer}
    </Container>
  );
};

export default PageLayout;
