import PageLayout from "../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import networkConfig from "../config/networkConfig";
import { SHLContext } from "../Contexts";
import { useErrorBoundary } from "react-error-boundary";
import { safeFetch } from "../utils/dataUtil";
import { Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import DisclaimerModal from "../components/DisclaimerModal";
import appConfig from "../config/appConfig";
import LoadingSpinner from "../components/LoadingSpinner";
import PageHeader from "../components/PageHeader";
import { InfoRounded } from "@mui/icons-material";
import { PINInput } from "../components/core";
import PageFooter from "../components/PageFooter";

const PINEntryPage = ({ shl }) => {
  const [translate, i18n] = useTranslation('translation', { keyPrefix: 'PIN' });

  const [passcode, setPasscode] = useState(['', '', '', '', '', '']);
  const [showPasscode, setShowPasscode] = useState(false);
  const [showModal, setShowModal] = useState(true);

  const { accessPIN: { maxAttempts, maxLength } } = appConfig;
  const [isLoading, setIsLoading] = useState(false);
  const [remainingAttempts, setRemainingAttempts] = useState(maxAttempts);

  const { showBoundary } = useErrorBoundary();
  const { setFiles, setEncryptionKey } = useContext(SHLContext);

  const submitPIN = async () => {
    setIsLoading(true);
    const fetchConfig = {
      method: "POST",
      headers: {
        "X-VSF-API-TOKEN": networkConfig.apiKey,
        "Accept-Language": i18n.language,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        recipient: "myhealth-ps-viewer",
        passcode: passcode.join(""),
      }),
    };

    const response = await safeFetch(shl.url, fetchConfig, showBoundary);
    if (response.ok) {
      const responseJSON = await response.json();
      setFiles(responseJSON.files);
      setEncryptionKey(shl.key);
    } else {
      // Real errors will be caught in the fetch function
      setRemainingAttempts(response.remainingAttempts);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // When the passcode changes, check if we can autosubmit
    if (!passcode.some((value) => (value === '' || !value)) && remainingAttempts === maxAttempts) {
      submitPIN();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passcode]);

  const isLocked = remainingAttempts === 0;
  const isIncorrect = remainingAttempts < maxAttempts;

  return (
    <>
      {isLoading && <LoadingSpinner backdrop />}
      <DisclaimerModal open={showModal} closeModal={() => setShowModal(false)} />
      <PageLayout header={<PageHeader />} footer={<PageFooter />}>
        <Stack sx={{
          direction: 'column',
          alignItems: 'center',
          paddingX: 4,
          paddingY: 5,
          gap: 4,
        }}>
          <Typography align="center" variant="h_md" fontWeight="bold">{isLocked ? translate('locked') : translate('pinRequired')}</Typography>
          <Typography align="center" variant="body_sm">{isLocked ? translate('maximumNumber') : translate('pleaseEnterPIN')}</Typography>
          <Stack spacing={1} alignItems="center">
            {isIncorrect && (
              <Typography variant="h_xxs" color="error.main" alignSelf="flex-start">
                {`${remainingAttempts}${translate(remainingAttempts !== 0 ? 'attemptsRemaining' : 'zeroAttemptsRemaining')}`}
              </Typography>
            )}
            <PINInput
              value={passcode}
              onChange={setPasscode}
              length={maxLength}
              showValue={showPasscode || isLocked}
              disabled={isLocked}
              error={isIncorrect && !isLocked}
            />
            {isIncorrect && (
              <Stack direction="row" spacing={1} alignItems="center" alignSelf="flex-start" color="error.main">
                <InfoRounded />
                <Typography variant="body_sm">{isLocked ? translate('lockedAccess') : translate('incorrectPIN')}</Typography>
              </Stack>
            )}
            {!isLocked && (
              <FormControlLabel
                label={translate('showPIN')}
                control={<Checkbox color="primary" onChange={() => setShowPasscode(!showPasscode)} />}
              />
            )}
            {!isLocked && isIncorrect && (
              <Button variant="contained" fullWidth onClick={submitPIN}>{translate('continue')}</Button>
            )}
          </Stack>
        </Stack>
      </PageLayout>
    </>
  );
};

export default PINEntryPage;
