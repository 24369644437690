import { Typography, Stack, Button, Container } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PageLayout from '../layouts/PageLayout';
import {
  ErrorOutline,
  InfoOutlined,
  WarningAmberOutlined,
} from '@mui/icons-material';
import { VSError } from '../model/error';
import { ErrorSeverities, ErrorTypes } from '../constants';
import appConfig from '../config/appConfig';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import LoadingSpinner from '../components/LoadingSpinner';

const iconStyles = {
  width: '96px',
  height: '96px',
};

const getIcon = (errorSeverity) => {
  switch (errorSeverity) {
    case ErrorSeverities.INFO:
      return <InfoOutlined color='primary' sx={iconStyles} />;
    case ErrorSeverities.WARNING:
      return <WarningAmberOutlined color='warning' sx={iconStyles} />;
    case ErrorSeverities.CRITICAL:
      return <ErrorOutline sx={{ ...iconStyles, color: 'error.dark' }} />;
    default:
      return null; // All errors should have a valid a severity level
  }
};

const ErrorPage = ({ error, resetErrorBoundary }) => {
  const [translate] = useTranslation();

  const errorType = error.type || ErrorTypes.UNKNOWN;
  const isVSError = error instanceof VSError && errorType !== ErrorTypes.GENERATING;
  const errorSeverity = appConfig.errorDisplayMapping[errorType];

  const errorFooter = (
    <PageFooter>
      {[ErrorSeverities.INFO, ErrorSeverities.GENERATING].includes(errorSeverity) && (
        <Container maxWidth="sm" sx={{
          alignItems: 'center',
        }}>
          <Button onClick={resetErrorBoundary} fullWidth>{translate('errorPage.tryAgain')}</Button>
        </Container>
      )}
    </PageFooter>
  );

  return (
    <PageLayout header={<PageHeader />} footer={errorFooter}>
      <Stack sx={{
        direction: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingX: 4,
        paddingY: 5,
        gap: 5,
      }}>
        {getIcon(errorSeverity)}
        <Typography variant="h_lg" textAlign="center">{translate(`errorMessages.${errorType}.title`)}</Typography>
        {ErrorSeverities.GENERATING === errorSeverity && (<LoadingSpinner size={80} thickness={6} />)}
        <Typography variant="body_md" textAlign="center">{translate(`errorMessages.${errorType}.info`)}</Typography>
        {isVSError && (error.code || error.exception) && (
          <Stack sx={{ width: '100%', color: 'text.secondary'}}>
            <Typography variant="body_sm">{translate('errorPage.code')}</Typography>
            <Typography variant="body_sm">{error.code || error.exception}</Typography>
          </Stack>
        )}
      </Stack>
    </PageLayout>
  );
};

export default ErrorPage;
