import * as React from 'react';
import { Input as BaseInput } from '@mui/base/Input';
import { Box, styled } from '@mui/system';

/**
 * https://mui.com/base-ui/react-input/#otp-input
 */
const PINInput = ({ length, value, onChange, showValue, disabled, error }) => {
  const [pinIndex, setPinIndex] = React.useState(0);
  const inputRefs = React.useRef(new Array(length).fill(null));

  const focusInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.focus();
    setPinIndex(targetIndex);
    if (targetIndex === value.length - 1 && value[targetIndex] !== '') {
      selectInput(targetIndex);
    }
  };

  const selectInput = (targetIndex) => {
    const targetInput = inputRefs.current[targetIndex];
    targetInput.select();
  };

  const handleKeyDown = (event, currentIndex) => {
    const otp = [...value];
    switch (event.key) {
      case ' ':
        event.preventDefault();
        break;
      case 'Delete':
        event.preventDefault();
        if (value[currentIndex] !== '' || currentIndex === 0) {
          otp[currentIndex] = '';
        } else {
          otp[currentIndex - 1] = '';
          focusInput(currentIndex - 1);
        }
        onChange(() => otp);
        break;
      case 'Backspace':
        event.preventDefault();
        if (value[currentIndex] !== '' || currentIndex === 0) {
          otp[currentIndex] = '';
        } else {
          otp[currentIndex - 1] = '';
          focusInput(currentIndex - 1);
        }
        onChange(() => otp);
        break;
      default:
        break;
    }
  };

  const handleChange = (event, currentIndex) => {
    const currentValue = event.target.value;

    // Only accept numeric input
    if (!currentValue || isNaN(currentValue)) {
      return;
    }

    let indexToEnter = 0;

    while (indexToEnter <= currentIndex) {
      if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
        indexToEnter += 1;
      } else {
        break;
      }
    }
    onChange(() => {
      const otp = [...value];
      const lastValue = currentValue[currentValue.length - 1];
      otp[indexToEnter] = lastValue;
      return otp;
    });
    if (currentValue !== '') {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1);
      } else {
        selectInput(currentIndex);
      }
    }
  };

  const handleClick = (event) => {
    event.preventDefault();
    focusInput(pinIndex);
  };

  const handlePaste = (event, currentIndex) => {
    event.preventDefault();
    const clipboardData = event.clipboardData;

    // Check if there is text data in the clipboard
    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain');
      pastedText = pastedText.substring(0, length).trim();
      let indexToEnter = 0;

      while (indexToEnter <= currentIndex) {
        if (inputRefs.current[indexToEnter].value && indexToEnter < currentIndex) {
          indexToEnter += 1;
        } else {
          break;
        }
      }

      const otp = [...value];
      let lastIndex = 0;

      for (let i = indexToEnter; i < pastedText.length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? ' ';
        otp[i] = lastValue;
        lastIndex = i;
      }

      focusInput(lastIndex + 1);
      onChange(() => otp);
    }
  };

  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
      {new Array(length).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <BaseInput
            slots={{
              input: InputElement,
            }}
            aria-label={`pin-digit-${index + 1}`}
            slotProps={{
              input: {
                ref: (ele) => {
                  inputRefs.current[index] = ele;
                },
                onKeyDown: (event) => handleKeyDown(event, index),
                onChange: (event) => handleChange(event, index),
                onClick: (event) => handleClick(event),
                onPaste: (event) => handlePaste(event, index),
                value: disabled ? '-' : (showValue ? (value[index] ?? '') : ((value[index] && '\u2022') ?? '')),
                inputMode: 'numeric',
                disabled,
              },
            }}
            error={error}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default PINInput;

const InputElement = styled('input')(
  ({ theme }) => `
  width: 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 0px;
  border-radius: 8px;
  text-align: center;
  color: ${theme.palette.text.primary};
  background: white;
  border: 2px solid white;

  .base--error & {
    background: ${theme.palette.error.light};
    border: 2px solid ${theme.palette.error.main};
  }

  &:disabled {
    background: ${theme.palette.grey[400]};
    border: 2px solid ${theme.palette.grey[400]};
  }

  &:focus {
    border-color: ${theme.palette.grey[700]};
    box-shadow: 0 0 0 3px ${theme.palette.grey[400]};
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);
