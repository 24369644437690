import { ExpandCircleDown } from '@mui/icons-material';
import displayConfig from '../../config/displayConfig';
import appConfig from '../../config/appConfig';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';

const SectionSelect = ({ sectionID }) => {
  const [translate] = useTranslation();
  const navigate = useNavigate();

  // Get supported sections from the display config
  const sectionConfigs = displayConfig[appConfig.spec].sections;

  //Set display value for the dropdown
  const renderValue = (value) => {
    return (
      <Stack gap={1}>
        <Typography variant='h_md' sx={{ textWrap: 'wrap' }}>
          {translate(`sections.${value}.title`)}
        </Typography>
        <Typography variant='body_xs' color='text.secondary' sx={{ textWrap: 'wrap' }}>
          {translate(`sections.${value}.subtitle`)}
        </Typography>
      </Stack>
    );
  };

  const changeSection = useCallback(
    (e) => {
      const section = e.target.value;
      // If user has changed the section, then navigate to the new page
      if (sectionID !== section) {
        navigate(`../${section}`, { replace: true });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [sectionID, navigate]
  );

  return (
    <FormControl id='section-select'>
      <Select
        value={sectionID}
        onChange={changeSection}
        IconComponent={ExpandCircleDown}
        renderValue={renderValue}
        sx={{
          p: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
            p: 0,
          },
          '& .MuiSelect-icon': {
            color: 'text.primary',
          },
        }}
      >
        {sectionConfigs.map((sectionConfig) => (
          <MenuItem key={sectionConfig.id} value={sectionConfig.id}>
            {translate(sectionConfig.titleLabel)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SectionSelect;
