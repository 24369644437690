/**
 * Represents a VeroSource error that can be returned by the gateway.
 * @class
 * @property {string} type Type of error thrown. Used by error page.
 * @property {string} status HTTP status code.
 * @property {string} code VeroSource error code (ex. VSF_MATME, VSF_MNSE, FHIR_FCCE).
 * @property {string} message Human readable error message provided by the gateway.
 * @property {string} exception VeroSource exception code (ex. MATME, MNSE, FHIR).
 */
class VSError extends Error {
  type = ''
  status = '';
  code = '';
  exception = '';

  constructor (type, status, message, code, exception) {
    super(message);
    this.type = type;
    this.status = status;
    this.code = code;
    this.exception = exception;
  };
};

export default VSError;
