import { ErrorSeverities, ErrorTypes } from "../constants";

const appConfig = {
  spec: 'psca',
  displayHeaderDate: true,
  displayHeaderHomeIcon: false,
  displayJSONBlob: true,
  accessPIN: {
    maxLength: 6,
    maxAttempts: 10000,
  },
  errorDisplayMapping: {
    [ErrorTypes.EXPIRED]: ErrorSeverities.CRITICAL,
    [ErrorTypes.GENERATING]: ErrorSeverities.GENERATING,
    [ErrorTypes.NOT_FOUND]: ErrorSeverities.CRITICAL,
    [ErrorTypes.UNKNOWN]: ErrorSeverities.CRITICAL,
  },
};
export default appConfig;
