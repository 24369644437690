/**
 * @fileoverview Utilities for formatting and handling Date objects
 */

import { getByDisplayKey } from "./displayKeyUtil";

/**
 * Formatting options for the `Intl.DateTimeFormat` object.
 */
const INTL_FORMAT_OPTIONS = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
};

const INTL_FORMAT_TIME_ZONE_OPTIONS = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
  timeZoneName: 'short',
}

/**
 * Given a date string, check if it contains a timestamp.
 * @param {string} date Date to check.
 * @returns {boolean} `true` if the date contains a timestamp, otherwise `false`.
 */
const dateContainsTimestamp = (date) => date.length > 10;

/**
 * Given a date string, check if it contains a time zone.
 * @param {string} date Date to check.
 * @returns {boolean} `true` if the date contains a time zone, otherwise `false`.
 */
const dateContainsTimeZone = (date) => date.length > 19;

/**
 * Given a date string, convert it to the user's current time zone.
 * @param {string} date Date to convert.
 * @returns {string} Date with user's time zone added.
 */
const addTimeZoneToDate = (date) => {
  if (!dateContainsTimestamp(date)) {
    date = date + "T00:00:00";
  }
  const offset = new Date().getTimezoneOffset();
  let offsetInHours = (offset / 60);
  const symbol = offsetInHours < 0 ? "+" : "-"
  offsetInHours = Math.abs(offsetInHours);

  const formattedHoursOffset = String(Math.floor(offsetInHours)).padStart(2, "0");
  const formattedMinutesOffset = String((offsetInHours % 1) * 60).padStart(2, "0");
  const formattedTimezone = `${symbol}${formattedHoursOffset}:${formattedMinutesOffset}`;
  return date + formattedTimezone;
};

/**
 * Given a date, convert it to a JS date object.
 * @param {string|Date} date Date to convert.
 * @returns {Date} Converted date.
 */
const getValidDate = (date) => {
  if (!(date instanceof Date)) {
    if (!dateContainsTimestamp(date)) {
      date = date + 'T00:00:00';
    } else if (!dateContainsTimeZone(date)) {
      // Add time zone offset to string
      date = addTimeZoneToDate(date);
    }
    return new Date(date);
  }
  return date;
}

/**
 * Use the `Intl` API to format a date for the user's language and time zone.
 * @param {string|Date} date Date to format.
 * @param {string} lang Language code.
 * @returns {string} Formatted date.
 */
export const formatDateAsText = (date, lang = 'en', timeZone = false) => {
  if (!date) {
    return '';
  }

  const format = timeZone ? INTL_FORMAT_TIME_ZONE_OPTIONS : INTL_FORMAT_OPTIONS;

  const validDate = getValidDate(date);
  const dateTimeFormat = new Intl.DateTimeFormat(lang, format);
  return dateTimeFormat.format(validDate);
};

/**
 * Use the `Intl` API to format a date to parts for the user's language and time zone.
 * @param {string|Date} date Date to format.
 * @param {string} lang Language code.
 * @param {boolean} timeZone Flag to enable the time and time zone portion of the date.
 * @returns {array} Array of formatted date parts (ex. `[2024, 'Jan', 1]`).
 */
export const formatDateAsParts = (date, lang, timeZone = false) => {
  if (!date) {
    return '';
  }

  const format = timeZone ? INTL_FORMAT_TIME_ZONE_OPTIONS : INTL_FORMAT_OPTIONS;

  const validDate = getValidDate(date);
  const dateTimeFormat = new Intl.DateTimeFormat(lang, format);
  const parts = dateTimeFormat.formatToParts(validDate) || [];

  return Object.keys(format).map(
    (option) => parts.find((part) => part.type === option)?.value || ''
  );
};

/**
 * Remove a date's timestamp values so it can be sorted by date only.
 * @param {Date} date Date to format.
 */
const formatDateForSort = (date) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
};

/**
 * Sort an array of bundle entries by most to least recent date value.
 * @param {array} entries Bundle entries to sort.
 * @param {string} sortKey Display key pointing to a date value.
 * @returns {array} New array with the elements in descending order by date value.
 */
export const sortByDateFieldDescending = (entries = [], sortKey = '', fallbackSortKey = '') => {
  return entries.toSorted(
    (a, b) => {
      const aDate = new Date(getByDisplayKey(a, sortKey));
      const bDate = new Date(getByDisplayKey(b, sortKey));

      formatDateForSort(aDate);
      formatDateForSort(bDate);

      if (aDate.getTime() === bDate.getTime()) {
        const aValue = getByDisplayKey(a, fallbackSortKey);
        const bValue = getByDisplayKey(b, fallbackSortKey);

        return aValue.localeCompare(bValue);
      }

      return bDate - aDate;
    }
  );
};
