/**
 * Represents an error that can occur anywhere in the code.
 * @class
 * @property {string} type Type of error thrown. Used by error page.
 * @property {string} status HTTP status code.
 * @property {string} message Human readable error message.
 */
class GenericError extends Error {
  type = '';
  status = '';

  constructor (type, status, message) {
    super(message);
    this.type = type;
    this.status = status;
  }
};

export default GenericError;
